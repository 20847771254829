import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AnimatedCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Align content to the top
  align-items: flex-start; // Align items to the left
  padding: 20px;
  border-radius: 15px;
  background: linear-gradient(#131219, #131219) padding-box,
              linear-gradient(135deg, #070707, #687aff) border-box; // Gradient background
  width: 100%; // Full width to utilize space better
  max-width: 800px; // Limit maximum width on larger screens
  height: auto; // Height adjusts to content
  color: white; // Text color for the whole card
  overflow: hidden; // Prevents content from spilling out

  @media (max-width: 768px) {
    font-size: 16px; // Smaller font size for mobile readability
    padding: 15px 10px; // Smaller padding on mobile
  }
`;

const Title = styled.h2`
  margin: 0 0 10px 0; // Spacing below the title
  font-size: 24px; // Reduced font size for mobile

  @media (max-width: 768px) {
    font-size: 20px; // Even smaller font size for mobile
  }
`;

const Description = styled.p`
  font-size: 18px; // Font size for the description
  margin-top: 15px; // Reduced space above the description

  @media (max-width: 768px) {
    font-size: 16px; // Smaller font size for mobile
  }
`;

const Card = ({ color, title, description, angle }) => {
  return (
    <AnimatedCard style={{ backgroundColor: color, angle }}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </AnimatedCard>
  );
};

export default Card;
