import React, { useEffect } from 'react';
import { useScrollContext } from '../contexts/ScrollContext'; // Verify this import path matches your project structure

const Layout = ({ children }) => {
  const { isLocked } = useScrollContext();

  useEffect(() => {
    console.log("Scroll Lock Status:", isLocked); // This will log the current status of scroll lock
    document.body.style.overflow = isLocked ? 'hidden' : 'auto'; // Applies or removes the scroll lock
  }, [isLocked]);

  return (
    <div>
      {children} 
    </div>
  );
};

export default Layout;

