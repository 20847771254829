import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Lenis from '@studio-freight/lenis';
import HomePage from './pages/HomePage';
import TermsPage from './pages/TermsPage'; // Import Terms of Service Page
import PrivacyPage from './pages/PrivacyPage'; // Import Privacy Policy Page
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { ScrollProvider } from './contexts/ScrollContext';
import GlobalStyle from './styles/Global-styles';
import Layout from './components/Layout';

function App() {
  const [lockScroll, setLockScroll] = useState(false);

  useEffect(() => {
    const lenis = new Lenis({
      duration: .8,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      direction: 'vertical',
      smooth: true,
      smoothTouch: false,
      touchMultiplier: 3.5,
    });

    function handleWheel(event) {
      event.preventDefault();
      const destination = window.pageYOffset + event.deltaY * 10.0;
      lenis.scrollTo(destination);
    }

    window.addEventListener('wheel', handleWheel, { passive: false });

    function raf(time) {
      if (!lenis.destroyed) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }
    }
    requestAnimationFrame(raf);

    function handleScrollControl() {
      if (lockScroll) {
        window.removeEventListener('wheel', handleWheel);
        lenis.destroy();
      }
    }

    window.addEventListener('scroll', handleScrollControl);

    return () => {
      lenis.destroy();
      window.removeEventListener('scroll', handleScrollControl);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [lockScroll]);

  return (
    <Router>
      <ScrollProvider>
        <GlobalStyle />
        <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Layout>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/terms" element={<TermsPage />} /> // Route for Terms of Service
              <Route path="/privacy" element={<PrivacyPage />} /> // Route for Privacy Policy
            </Routes>
            <Footer />
          </Layout>
        </div>
      </ScrollProvider>
    </Router>
  );
}

export default App;