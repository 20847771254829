import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    max-width: 100vw;
    overflow-x: hidden;  // Prevent horizontal scrolling
  }

  * {
    box-sizing: border-box;  // Helps with padding and border included in width
    // outline: 1px solid red;  // Uncomment for debugging which elements overflow
  }
`;

export default GlobalStyle;