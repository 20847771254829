import styled from 'styled-components';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; // Minimum height of 100% of the viewport
  width: 100%;
  padding: 50px 0; // Top and bottom padding
  box-sizing: border-box; // Padding included in the width and height calculations
  overflow: hidden; // Prevents any content from spilling out

    @media (max-width: 768px) {
    padding: 30px 0;
    min-height: 80vh;
  }
`;

export default SectionContainer