import React, { createContext, useContext, useState, useEffect } from 'react';

const ScrollContext = createContext({
  isLocked: false,
  setScrollLock: () => {}
});

export const useScrollContext = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
  const [isLocked, setScrollLock] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isLocked ? 'hidden' : 'auto';
  }, [isLocked]);

  return (
    <ScrollContext.Provider value={{ isLocked, setScrollLock }}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollContext;