import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import emailjs from 'emailjs-com';

const rotateGradient = keyframes`
  from {
    background-position: 0% 50%;
  }
  to {
    background-position: 100% 50%;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #1B1212;
  border: 3px solid transparent;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
  text-align: center;
  background: linear-gradient(#131219, #131219) padding-box, 
              linear-gradient(${props => `${props.angle}deg, #070707, #687aff`}) border-box;
  animation: ${rotateGradient} 2s linear infinite;
`;

const ContactTitle = styled.h2`
  color: white;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #333;
  background: #333;
  color: white;
  outline: none;

  &:focus {
    border-color: #555;
  }
`;

const Textarea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #333;
  background: #333;
  color: white;
  height: 100px;
  outline: none;

  &:focus {
    border-color: #555;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #D3D3D3;
  color: #000;

  &:hover {
    background-color: #FFFFFF;
  }
`;

const ContactFormComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setAngle(prev => (prev + 1) % 360);
    }, 100); // Adjust interval speed as needed

    return () => clearInterval(interval);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.sendForm('your_service_id', 'your_template_id', e.target, 'your_user_id')
      .then((result) => {
        alert('Message sent successfully');
        setFormData({ name: '', email: '', message: '' });
        setIsSubmitting(false);
      }, (error) => {
        console.error('Error sending message:', error);
        alert('Failed to send message');
        setIsSubmitting(false);
      });
  };

  return (
    <CardContainer angle={angle}>
      <ContactTitle>Contact Us</ContactTitle>
      <ContactForm onSubmit={handleSubmit}>
        <Input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
        <Input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" required />
        <Textarea name="message" value={formData.message} onChange={handleChange} placeholder="Your Message" required />
        <Button type="submit" disabled={isSubmitting}>Send Message</Button>
      </ContactForm>
    </CardContainer>
  );
};

export default ContactFormComponent;
