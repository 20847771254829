import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Card from '../components/Card';
import CarouselControl from '../components/CarouselControl';
import styled from 'styled-components';

const ScrollContainer = styled.div`
  overflow: visible; // Changed from hidden to visible to prevent clipping
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
  height: 900px; // Adjust based on your layout needs
`;

const cardVariants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
    transition: { duration: 0.5, ease: 'easeInOut' }  // Smoother transition for entering
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    transition: { duration: 0.5, ease: 'easeInOut' }  // Smoother transition for centering
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
    transition: { duration: 0.5, ease: 'easeInOut' }  // Smoother transition for exiting
  }),
};

const AboutUsSection = () => {
  const [cardHeight] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  console.log("Button offset:", cardHeight + 20); // Debugging log
  
  const descriptionText = `Our system allows healthcare providers to query patient records using natural language, simplifying the process of finding specific information. Whether searching for past medications, suspected conditions, or relevant notes, doctors can input terms like "recent surgeries" or "antibiotic allergies" and receive references to pertinent sections of the medical history. For instance, searching for "recent surgeries" could highlight entries about "laparoscopic cholecystectomy" performed two months ago. This feature ensures that doctors spend less time sifting through documents and more time focusing on patient care.`;
  const descriptionText2 = (
    <>
      <p>
        Managing extensive medical histories can be particularly challenging, especially for patients with complex conditions or those transitioning between healthcare providers. Often, the responsibility of communicating critical information falls on the patient, which can lead to missed details and incomplete histories. This burden is especially taxing for those with chronic conditions who might struggle to convey their comprehensive medical backgrounds accurately.
      </p>
      <p>
        Our AI-powered EHR system alleviates this cognitive burden by streamlining the retrieval and summarization of essential medical information. By presenting relevant data in a clear and accessible manner, our system reduces the need for patients to advocate for themselves, ensuring that healthcare providers have a complete understanding of the patient's history. This efficiency not only prevents healthcare provider burnout and enhances operational efficiency but, most importantly, guarantees that patients receive the most informed and effective care possible.
      </p>
      <p>
        By integrating advanced AI capabilities, our system ensures that healthcare providers can quickly access and interpret critical information, leading to improved patient outcomes and higher quality care. Our AI suite can be used with our EHR or integrated into your existing EHR system. This transformative approach supports a more efficient healthcare delivery system, where both providers and patients benefit from the streamlined management of medical records.
      </p>
    </>
  );

  const cards = [
    { color: "#1B1212", title: "Contextual Summarization for Informed Care", description: "When a patient presents specific symptoms or is referred for particular reasons, our AI analyzes their complete medical history to generate a concise and relevant summary. This summary highlights significant findings, such as previously overlooked test results that now indicate potential concerns when viewed in light of current symptoms. For example, if a patient had a slightly abnormal liver enzyme level a year ago and now presents with jaundice, our system will flag the past result as relevant. By bringing these crucial details to the forefront, our system aids doctors in making more accurate diagnoses and assists in creating effective treatment plans." },
    { color: "#1B1212", title: "Medical Record Search", description: descriptionText },
    { color: "#1B1212", title: "Reducing Workload and Enhancing Patient Experience", description: descriptionText2 },
  ];

  const paginate = (newDirection) => {
    setDirection(newDirection);
    setCurrentIndex((prev) => (prev + newDirection + cards.length) % cards.length);
  };

  return (
    <ScrollContainer>
      <CarouselControl direction="left" onClick={() => paginate(-1)} />
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentIndex}
          variants={cardVariants}
          custom={direction}
          initial="enter"
          animate="center"
          exit="exit"
        >
          <Card
            color={cards[currentIndex % cards.length].color}
            title={cards[currentIndex % cards.length].title}
            description={cards[currentIndex % cards.length].description}
          />
        </motion.div>
      </AnimatePresence>
      <CarouselControl direction="right" onClick={() => paginate(1)} />
    </ScrollContainer>
  );
};

export default AboutUsSection;
