import React from 'react';
import styled from 'styled-components';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

const Button = styled.button`
  position: absolute;
  bottom: 10px; // Adjusted to be visible within the container
  border: none;
  cursor: pointer;
  color: black;
  background: none;
  font-size: 30px;
  z-index: 10;

  ${props => props.direction === 'left' ? 'left: 40%; transform: translateX(-60%);' : 'right: 40%; transform: translateX(40%);'}

  @media (max-width: 768px) {
    font-size: 40px; // Smaller icons on mobile
    bottom: -50px; // Slightly lower to avoid overlapping with other elements
  }
`;

const CarouselControl = ({ direction, onClick }) => {
  return (
    <Button onClick={onClick} direction={direction}>
      {direction === 'left' ? <MdChevronLeft /> : <MdChevronRight />}
    </Button>
  );
};

export default CarouselControl;
