import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FiPlus } from 'react-icons/fi';

// Keyframes for the gradient rotation
const rotateGradient = keyframes`
  from {
    background-position: 0% 50%;
  }
  to {
    background-position: 100% 50%;
  }
`;

// Styled FAQ container with dynamic gradient angle
const FAQContainer = styled.div`
  padding: 20px;
  background: #1B1212;
  border-radius: 8px;
  margin-top: 20px;
  border: 3px solid transparent;
  background: linear-gradient(#131219, #131219) padding-box, 
              linear-gradient(${props => `${props.angle}deg, #070707, #687aff`}) border-box;
  animation: ${rotateGradient} 2s linear infinite;

  @media (max-width: 768px) {
    padding: 20px; // Adds padding on all sides for smaller screens
  }
`;

const FAQTitle = styled.h2`
  color: white;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
`;

const QuestionButton = styled.button`
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 20px;
  font-size: 18px;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  &.active {
    color: #0077cc;
  }
`;

const QuestionText = styled.div`
  font-weight: bold;
  background: linear-gradient(
    to right, 
    #b2a8fd, 
    #8678f9 50%, 
    #c7d2fe 75%, 
    #9a8dfd 75%
  );
  background-size: 200% auto;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${rotateGradient} 5.5s linear infinite;
`;

const Answer = styled.div`
  max-height: ${props => props.isActive ? '1000px' : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background: #fff;
  padding: 0 10px;
  border-radius: 4px;
  font-weight: 400;
  word-wrap: break-word; // Ensures words do not overflow their container
  white-space: normal; // Ensures text wraps to next line when needed
  line-height: 1.6; // Adjust line height for better readability

  &.open {
    max-height: ${props => props.isActive ? '1000px' : '0'};
    padding: 10px;
    overflow: auto; // Allows scrolling within the container if content overflows
  }

  p {
    margin: 0;
    word-break: break-word; // Breaks the word at the end of the line
  }
`;

const faqs = [
  { question: "Will our systems ever generate inaccurate info?", answer: "Our AI focuses on delivering precise and contextually relevant information by strictly operating within the bounds of the medical record. This approach ensures that only verified data is presented. By consolidating and highlighting key data points, our system assists healthcare providers in accessing critical patient information quickly and efficiently, supporting them in making informed decisions based on accurate medical histories. " },
  { question: "What is FHIR?", answer: "The Fast Healthcare Interoperability Resources standard is a set of rules and specifications for exchanging electronic health care data. It is designed to be flexible and adaptable, so that it can be used in a wide range of settings and with different health care information systems, including ABDM. ABDM utilization requires FHIR, our EHR will ensure compatabiltiy with this standard for your clinic." },
  { question: "How difficult will onboarding into your system be?", answer: "Our EHR software ensures a smooth and hassle-free transition for your healthcare practice. We provide comprehensive support throughout the migration process, including data transfer, system integration, and staff training. Our dedicated team ensures minimal disruption to your operations, allowing you to focus on providing quality patient care." },
  { question: "How secure will the platform be?", answer: "Our AI platform integrates advanced security frameworks and state-of-the-art encryption protocols to ensure the highest level of data protection and privacy for patient information. We utilize comprehensive, industry-leading security measures to safeguard against unauthorized access and data breaches, providing a secure and reliable environment for healthcare data management." }
];

const FAQ = () => {
  const [angle, setAngle] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = index => {
    setActiveIndex(prevIndex => prevIndex === index ? null : index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setAngle(prev => (prev + 1) % 360);
    }, 100);
    return () => clearInterval(interval);
  }, []);


  return (
    <FAQContainer angle={angle}>
      <FAQTitle>FAQ</FAQTitle>
      {faqs.map((faq, index) => (
        <React.Fragment key={index}>
          <QuestionButton 
            className={activeIndex === index ? "active" : ""} 
            onClick={() => toggleAccordion(index)}
          >
            <QuestionText>{faq.question}</QuestionText>
            <FiPlus className={activeIndex === index ? "rotate" : ""} />
          </QuestionButton>
          <Answer 
            className={activeIndex === index ? "open" : ""} 
            isActive={activeIndex === index}
          >
            <p>{faq.answer}</p>
          </Answer>
        </React.Fragment>
      ))}
    </FAQContainer>
  );
};


export default FAQ;
