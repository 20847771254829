import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  color: black;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  flex-shrink: 0;
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  margin: 0 10px;  // Adds 10px margin to each side
`;

const Footer = () => {
  return (
    <FooterContainer>
      &copy; 2024 Ayurdata Pvt Ltd., a subsidiary of Elysium Arcanum Inc. All Rights Reserved. |
      <StyledLink to="/terms">Terms of Service</StyledLink> |
      <StyledLink to="/privacy">Privacy Policy</StyledLink>
    </FooterContainer>
  );
};

export default Footer;