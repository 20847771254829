import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll'; // Import Link from react-scroll

const NavbarContainer = styled.nav`
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: white; // Ensures the navbar is visible against any background
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); // Adds a subtle shadow for better separation
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin-top: 0; // Removes any top margin
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  justify-content: flex-start;
  color: #000;
`;

const LogoImage = styled.img`
  height: 50px;
  margin-right: 10px;
`;

const NavItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 1;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    position: fixed;
    background-color: white; // Ensures visibility on all screens
    top: 50px; // Adjust as per the actual height of the navbar
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    font-size: 1.2em;
    padding-top: 10px;
  }
`;

const NavItem = styled(Link)`
  color: #000;
  font-size: 1em;
  cursor: pointer;
  margin: 0 15px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #000;
  }

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
    color: #000;
    font-size: 2.0em;
    padding: 10px;
    z-index: 1100;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

    // A helper function to close the navbar when a link is clicked
    const closeMenu = () => {
      if (isOpen) {
        toggleMenu();
      }
    };

  return (
    <NavbarContainer>
      <LogoContainer>
        <LogoImage src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Logo" />
        <a href="https://ayurdata.ai" style={{ color: '#000', textDecoration: 'none', marginLeft: '10px', fontWeight: '500' }}>
          Ayurdata
        </a>
      </LogoContainer>
      <MenuIcon onClick={toggleMenu}>☰</MenuIcon>
      <NavItems isOpen={isOpen}>
        <NavItem to="about-us" spy={true} smooth={true} offset={150} duration={500} onClick={closeMenu}>
          AI Services
        </NavItem>
        <NavItem to="contact" spy={true} smooth={true} offset={-70} duration={500} onClick={closeMenu}>
          Contact Us
        </NavItem>
        <NavItem to="faq" spy={true} smooth={true} offset={-70} duration={500} onClick={closeMenu}>
          FAQ
        </NavItem>
      </NavItems>
    </NavbarContainer>
  );
};

export default Navbar;