import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ScrollProvider } from './contexts/ScrollContext'; // Adjust the import path as necessary

const container = document.getElementById('root');
const root = createRoot(container); // Create a root.

root.render(
  <React.StrictMode>
    <ScrollProvider>
      <App />
    </ScrollProvider>
  </React.StrictMode>
);
