import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
`;

const Header = styled.h1`
  color: #333;
`;

const SubHeader = styled.h2`
  color: #666;
`;

const Paragraph = styled.p`
  margin: 10px 0;
`;

const List = styled.ul`
  list-style: inside square;
`;

const ListItem = styled.li``;

const Bold = styled.span`
  font-weight: bold;
`;

function TermsPage() {
  return (
    <Container>
      <Header>Terms of Service</Header>
      <Paragraph>Last updated: June 11th, 2024</Paragraph>
      <Paragraph>
        These Terms of Service ("Terms") constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and Ayurdata Pvt Ltd. ("Company," "we," "us," or "our"), concerning your access to and use of the <a href="http://www.ayurdata.in">www.ayurdata.in</a> website as well as any other media form, media channel, mobile website, or mobile application related, linked, or otherwise connected thereto (collectively, the "Site"). By accessing the Site, you agree that you have read, understood, and agreed to be bound by all of these Terms. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
      </Paragraph>
      <Paragraph>
        Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of these Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms by your continued use of the Site after the date such revised Terms are posted.
      </Paragraph>

      <SubHeader>1. OUR SERVICES</SubHeader>
      <Paragraph>
        The information provided when using the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.
      </Paragraph>

      <SubHeader>2. INTELLECTUAL PROPERTY RIGHTS</SubHeader>
      <Paragraph>
        Our Intellectual Property: We are the owner or the licensee of all intellectual property rights in our Site, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").
      </Paragraph>

      <SubHeader>3. USER REPRESENTATIONS</SubHeader>
      <Paragraph>
        By using the Site, you represent and warrant that:
      </Paragraph>
      <List>
        <ListItem>All registration information you submit will be true, accurate, current, and complete;</ListItem>
        <ListItem>You will maintain the accuracy of such information and promptly update such registration information as necessary;</ListItem>
        <ListItem>You have the legal capacity and you agree to comply with these Terms;</ListItem>
        <ListItem>You are not under the age of 18;</ListItem>
        <ListItem>You will not access the Site through automated or non-human means, whether through a bot, script, or otherwise;</ListItem>
        <ListItem>You will not use the Site for any illegal or unauthorized purpose;</ListItem>
        <ListItem>Your use of the Site will not violate any applicable law or regulation.</ListItem>
      </List>

      <SubHeader>4. USER REGISTRATION</SubHeader>
      <Paragraph>
        You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
      </Paragraph>

      <SubHeader>5. PROHIBITED ACTIVITIES</SubHeader>
      <Paragraph>
        You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
      </Paragraph>

      <SubHeader>6. USER GENERATED CONTRIBUTIONS</SubHeader>
      <Paragraph>
        The Site does not offer users the opportunity to submit or post content. We may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions will not be viewable by other users of the Site or through third-party websites.
      </Paragraph>

      <SubHeader>7. CONTRIBUTION LICENSE</SubHeader>
      <Paragraph>
        By submitting suggestions or other feedback regarding the Site, you agree that we can use and share such feedback for any purpose without compensation to you.
      </Paragraph>

      <SubHeader>8. SERVICES MANAGEMENT</SubHeader>
      <Paragraph>
        We reserve the right, but not the obligation, to:
      </Paragraph>
      <List>
        <ListItem>Monitor the Site for violations of these Terms;</ListItem>
        <ListItem>Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms;</ListItem>
        <ListItem>In our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;</ListItem>
        <ListItem>Otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.</ListItem>
      </List>

      <SubHeader>9. TERM AND TERMINATION</SubHeader>
      <Paragraph>
        These Terms shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON.
      </Paragraph>

      <SubHeader>10. MODIFICATIONS AND INTERRUPTIONS</SubHeader>
      <Paragraph>
        We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
      </Paragraph>

      <SubHeader>11. GOVERNING LAW</SubHeader>
      <Paragraph>
        These Terms shall be governed by and defined following the laws of India. Ayurdata Pvt Ltd. and yourself irrevocably consent that the courts of India shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these Terms.
      </Paragraph>

      <SubHeader>12. DISPUTE RESOLUTION</SubHeader>
      <Paragraph>
        Any dispute arising out of or in connection with these Terms, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by arbitration under the Arbitration and Conciliation Act, 1996. The seat, or legal place, of arbitration shall be New Delhi, India.
      </Paragraph>

      <SubHeader>13. CORRECTIONS</SubHeader>
      <Paragraph>
        There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
      </Paragraph>

      <SubHeader>14. DISCLAIMER</SubHeader>
      <Paragraph>
        THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK.
      </Paragraph>

      <SubHeader>15. LIMITATIONS OF LIABILITY</SubHeader>
      <Paragraph>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES.
      </Paragraph>

      <SubHeader>16. INDEMNIFICATION</SubHeader>
      <Paragraph>
        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of your use of the Site.
      </Paragraph>
      <SubHeader>17. USER DATA</SubHeader>
      <Paragraph>
        We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site.
      </Paragraph>

      <SubHeader>18. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</SubHeader>
      <Paragraph>
        Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing.
      </Paragraph>

      <SubHeader>19. MISCELLANEOUS</SubHeader>
      <Paragraph>
        These Terms and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us.
      </Paragraph>

      <SubHeader>20. CONTACT US</SubHeader>
      <Paragraph>
        In order to resolve a complaint regarding the Site or to receive further information regarding the use of the Site, please contact us at:
      </Paragraph>
      <Paragraph>
        Ayurdata Pvt Ltd.<br/>
        EG 32, ASHIANA GARDEN<br/>
        BHIWADI, RAJASTHAN 301019<br/>
        Email: tos@ayurdata.ai<br/>
        Phone: (+1) 341-207-2186
      </Paragraph>
    </Container>
  );
}

export default TermsPage;