import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
`;

const Header = styled.h1`
  color: #333;
`;

const Section = styled.section`
  margin-top: 20px;
`;

const SubHeader = styled.h2`
  color: #666;
`;

const Paragraph = styled.p`
  margin: 10px 0;
`;

const List = styled.ul`
  list-style: inside square;
`;

const ListItem = styled.li``;

const Bold = styled.span`
  font-weight: bold;
`;

function PrivacyPage() {
  return (
    <Container>
      <Header>Privacy Policy</Header>
      <Paragraph>Last updated: June 11th, 2024</Paragraph>
      <Paragraph>This privacy notice for Ayurdata Pvt Ltd. ("Ayurdata," "Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:</Paragraph>
      <List>
        <ListItem>Visit our website at www.ayurdata.in, or any website of ours that links to this privacy notice</ListItem>
        <ListItem>Engage with us in other related ways, including any sales, marketing, or events</ListItem>
      </List>
      <Paragraph>If you have questions or concerns, please contact us at privacy@ayurdata.ai.</Paragraph>
      
      <Section>
        <SubHeader>SUMMARY OF KEY POINTS</SubHeader>
        <Paragraph>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by using our table of contents below to find the section you are looking for.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>1. WHAT INFORMATION DO WE COLLECT?</SubHeader>
        <Paragraph><Bold>Personal information you disclose to us</Bold></Paragraph>
        <Paragraph>In Short: We collect personal information that you provide to us.</Paragraph>
        <Paragraph>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>2. HOW DO WE PROCESS YOUR INFORMATION?</SubHeader>
        <Paragraph>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</SubHeader>
        <Paragraph>We may share information in specific situations and with specific third parties.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</SubHeader>
        <Paragraph>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>5. HOW DO WE HANDLE YOUR THIRD-PARTY LOGINS?</SubHeader>
        <Paragraph>If you choose to register or log in to our Services using a third-party account, we may have access to certain information about you.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>6. HOW LONG DO WE KEEP YOUR INFORMATION?</SubHeader>
        <Paragraph>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</SubHeader>
        <Paragraph>We aim to protect your personal information through a system of organizational and technical security measures.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>8. DO WE COLLECT INFORMATION FROM MINORS?</SubHeader>
        <Paragraph>We may process data from children under 18 years of age but only on behalf of their healthcare provider.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>9. WHAT ARE YOUR PRIVACY RIGHTS?</SubHeader>
        <Paragraph>You may review, change, or terminate your account at any time.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>10. CONTROLS FOR DO-NOT-TRACK FEATURES</SubHeader>
        <Paragraph>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>11. DO WE MAKE UPDATES TO THIS NOTICE?</SubHeader>
        <Paragraph>Yes, we will update this notice as necessary to stay compliant with relevant laws.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</SubHeader>
        <Paragraph>If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO), Jake Bringetto, by email at Jake@ayurdata.ai, by phone at (+1) 341-207-2186, or by post at:</Paragraph>
        <Paragraph>Ayurdata Pvt Ltd.<br/>Akshay Lamba<br/>EG 32, ASHIANA GARDEN<br/>BHIWADI, RAJASTHAN 301019</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</SubHeader>
        <Paragraph>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please contact privacy@ayurdata.ai for assistance.</Paragraph>
      </Section>
    </Container>
  );
}

export default PrivacyPage;